import styled from 'styled-components';

export const Title = styled.div`
  margin-top: 70px;
  font-family: Muli;
  font-size: 72px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #faab06;
`;
