import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Breaker from '../components/Breaker';
import Testimony from '../components/Testimony';
import SEO from '../components/Seo';
import { Title } from '../styles/coaching';

const Coaching = ({ data }) => (
  <Layout>
    <SEO title="Coaching" keywords={[`paleo`, `health`, `wellness`, `coach`, `fitness`, `coaching`, `chef`, `mindfulness`, `desporto`, `gym`, `ginasio`, `ajuda`]} />
    <Header />

    <Grid>
      <Title>Coaching</Title>
      <Breaker />

      <Row>
        {data && data.allMarkdownRemark.edges.map(({ node }) => (
          <Col md={4} key={node.id}>
            <Testimony author={node} />
          </Col>
        ))}
      </Row>

    </Grid>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "testimonies" } } },
      sort: { order: DESC, fields: [frontmatter___date]}
    ) {
      edges {
        node {
          id
          frontmatter {
            author {
              name
              quote
            }
            cover
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default Coaching;
