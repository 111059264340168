import React from 'react';

import { COACHING_PATH } from '../../constants/Routes';

import { Anchor, Avatar, Quote, Author } from './style';

const Testimony = ({ author }) => (
  <Anchor to={`${COACHING_PATH}/${author.fields.slug}`}>
    <Avatar src={author.frontmatter.cover} alt={author.frontmatter.author.name} />

    <Quote>
      "{author.frontmatter.author.quote}"
    </Quote>

    <Author>
      – {author.frontmatter.author.name}
    </Author>
  </Anchor>
);

export default Testimony;
