import styled from 'styled-components';
import { Link } from 'gatsby';

import { PRIMARY_COLOR } from '../../constants/colors';

export const Anchor = styled(Link)`
  display: block;
  text-align: center;
  text-decoration: none;
  margin-bottom: 45px;
  
  &:hover {
    img {
      border-color: ${PRIMARY_COLOR};
    }
  }
`;

export const Avatar = styled.img`
  width: 100px;
  border-radius: 250px;
  margin: 0 auto;
  display: inline-block;
  padding: 4px;
  border: 2px #ccc solid;
`;

export const Quote = styled.div`
  font-family: Playfair Display;
  color: #666;
  font-style: italic;
`;

export const Author = styled.div`
  color: #bbb;
  font-size: 15px;
`;
